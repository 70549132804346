import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getStructureActivity = createAsyncThunk('structureActivitySlice/getStructureActivity', async () => {
    try {
        let response = await axios.get('/activity', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStructureActivityOptions = createAsyncThunk('structureActivitySlice/getStructureActivityOptions', async () => {
    try {
        let response = await axios.get('/activity/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createStructureActivity = createAsyncThunk(
    'structureActivitySlice/createStructureActivity',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/activity', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Structure Activity added successfully.');
            dispatch(getStructureActivity());

            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);
            return rejectWithValue(err);
        }
    }
);

export const updateStructureActivity = createAsyncThunk(
    'structureActivitySlice/updateStructureActivity',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/activity/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Structure Activity updated successfully.');
            dispatch(getStructureActivity());

            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const deleteStructureActivity = createAsyncThunk(
    'structureActivitySlice/deleteStructureActivity',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/activity/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Structure Activity deleted successfully.');
            await dispatch(getStructureActivity());
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);
export const updateStatus = createAsyncThunk('structureActivitySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/activity/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        await dispatch(getStructureActivity());
        toast.success(response?.data?.message || 'status updated successfully !');

        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});
export const structureActivitySlice = createSlice({
    name: 'structureActivitySlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getStructureActivity.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStructureActivityOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default structureActivitySlice.reducer;
