import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../../../../service/axios';
import { toast } from 'react-toastify';

export const getSchBAnnexOne = createAsyncThunk('annexOneSlice/getSchBAnnexOne', async (id) => {
    try {
        let response = await axios.post('/schedule-b-annex-1/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSchBAnnexOneById = createAsyncThunk('annexOneSlice/getSchBAnnexOneById', async (id) => {
    try {
        let response = await axios.get('/schedule-b-annex-1/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSchBAnnexOne = createAsyncThunk('annexOneSlice/createSchBAnnexOne', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/schedule-b-annex-1', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Annex One added successfully.');
        await dispatch(getSchBAnnexOne(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateSchBAnnexOne = createAsyncThunk('annexOneSlice/updateSchBAnnexOne', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/schedule-b-annex-1/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Annex One updated successfully.');
        dispatch(getSchBAnnexOne(data?.data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});
export const deleteSchBAnnexOne = createAsyncThunk('annexOneSlice/deleteSchBAnnexOne', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/schedule-b-annex-1/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Annex One deleted successfully.');
        // await dispatch(getSchBAnnexOne());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTcsOptions = createAsyncThunk('tcsSlice/getTcsOptions', async (id) => {
    try {
        let response = await axios.get('/tcs/options/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSchBAnnexOneReporting = createAsyncThunk('annexOneSlice/getSchBAnnexOneReporting', async (id) => {
    try {
        let response = await axios.post('/sch-b-reporting/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSchBAnnexOneReportingById = createAsyncThunk('annexOneSlice/getSchBAnnexOneReportingById', async (id) => {
    try {
        let response = await axios.get('/sch-b-reporting/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSchBAnnexOneReporting = createAsyncThunk(
    'annexOneSlice/createSchBAnnexOneReporting',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/sch-b-reporting', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Annex One Reporting added successfully.');
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            return rejectWithValue(err);
        }
    }
);

export const updateSchBAnnexOneReporting = createAsyncThunk(
    'annexOneSlice/updateSchBAnnexOneReporting',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/sch-b-reporting/' + data?.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Annex One Reporting added successfully.');
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            return rejectWithValue(err);
        }
    }
);

export const deleteSchBAnnexOneReporting = createAsyncThunk('annexOneSlice/deleteSchBAnnexOneReporting', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/sch-b-reporting/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Annex One deleted successfully.');
        // await dispatch(getSchBAnnexOne());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const annexOneSlice = createSlice({
    name: 'annexOneSlice',
    initialState: {
        data: [],
        options: [],
        annexOneData: [],
        schBReporting: [],
        schBReportingById: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getSchBAnnexOne.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSchBAnnexOneById.fulfilled, (state, action) => {
            state.annexOneData = action?.payload;
            return;
        });
        builder.addCase(getTcsOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getSchBAnnexOneReporting.fulfilled, (state, action) => {
            state.schBReporting = action?.payload;
            return;
        });
        builder.addCase(getSchBAnnexOneReportingById.fulfilled, (state, action) => {
            state.schBReportingById = action?.payload;
            return;
        });
    }
});

export default annexOneSlice.reducer;
